.wrapper {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 0 0 0;
  background-color: #141414;
  color: #fafafa;
  grid-template-areas: 
    "leftSite"
    "rightSite"
    "bottomSite";

  @media (min-width:720px) {
    padding: 1rem 3rem;
  }
  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 15vh;
    align-items: center;
    padding: 0;
    grid-template-areas: 
    "leftSite rightSite"
    "bottomSite bottomSite";
  //   background-image: url(../assets/images/background/header-bg5.jpg);
  // background-position: center;
  // background-size: cover;
  }
}

.left {
  padding: 1rem;
  grid-area: leftSite;

  @media (min-width:720px) {
    padding: 2rem 3rem;
  }
  @media (min-width:1600px) {
    padding: 3rem 5rem;
    margin-left: .5rem;
  }

  .title {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase;

    @media (min-width:1200px) {
      font-size: 3rem;
      margin-top: 5rem;
    }
    @media (min-width:1600px) {
      font-size: 3.5rem;
    }
  
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934;
    }
  }
  .p {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%;

    @media (min-width:1200px) {
      font-size: 1.3rem;
      margin: 1rem;
      letter-spacing: 1px;
    }
    @media (min-width:1600px) {
      font-size: 1.5rem;
    }
  }

  .albums {
    padding: 0 1rem;
    font-size: 1.4rem;
    color: #FF2934;
    text-transform: uppercase;
    font-weight: 700;

    @media (min-width:1600px) {
      font-size: 1.5rem;
    }
  }
}

.right {
  padding: 1rem;
  height: 100%;
  grid-area: rightSite;

  @media (min-width:1200px) {
    padding: 3rem;
  }

  .video {
    margin: 0 auto;

    @media (min-width:720px) {
      padding: 3rem 0;
      // height: 100% !important;
    }
    @media (min-width:1200px) {
      padding: 3rem 2rem;
      width: 100% !important;
    }
    @media (min-width:1600px) {
      font-size: 4rem;
      transform: translateX(-20%);
    }
  }
}

.partners {
  width: 100%;
  background-color: #332E30;
  grid-area: bottomSite;

  @media (min-width:1200px) {
    height: 100%;
    clip-path: polygon(71% 5%, 100% 0, 90% 17%, 100% 42%, 100% 100%, 0 100%, 0 0, 30% 9%, 44% 0, 59% 9%);
    display: flex;
  }
  @media (min-width:1600px) {
    text-align: center;
  }

  .partnersTitle {
    padding: 1.5rem;
    font-size: 1.3rem;

    @media (min-width:1200px) {
      padding: 2rem;
      font-size: 1.6rem;
    }
    @media (min-width:1600px) {
      font-size: 2rem;
    }
  }

  .imgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (min-width:1200px) {
      flex-direction: row;
    }
  }

  .partnersLink {
    padding: .5rem;

    @media (min-width:1200px) {
      padding: 1rem;
    }

    .partnersImg {
      display: inline-block;
      width: 5rem;

      &Soft {
        display: inline-block;
        width: 4.3rem;

        @media (min-width:1200px) {
          width: 5.3rem;
        }
      }

      @media (min-width:1200px) {
        width: 6rem;
      }
    }
    
  }
}