body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #FF2934;
  border-radius: 5px;
}
