body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #FF2934;
  border-radius: 5px;
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.App_appWraper__2HaXL {
  font-family: 'Teko', sans-serif; }

.Header_wrapper__IwGFH {
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-image: url(/static/media/header-bg7.df0a6aae.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden; }
  .Header_wrapper__IwGFH .Header_logo__1Hs_S {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 60%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (min-width: 720px) {
      .Header_wrapper__IwGFH .Header_logo__1Hs_S {
        top: 25%;
        width: 50%; } }
    @media (min-width: 1200px) {
      .Header_wrapper__IwGFH .Header_logo__1Hs_S {
        top: 20%;
        width: 30%; } }
    @media (min-width: 1600px) {
      .Header_wrapper__IwGFH .Header_logo__1Hs_S {
        top: 10%;
        width: 30%; } }
  .Header_wrapper__IwGFH .Header_crowd__2Afrl {
    position: absolute;
    left: 50%;
    bottom: -30%;
    width: 200%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (min-width: 720px) {
      .Header_wrapper__IwGFH .Header_crowd__2Afrl {
        width: 150%; } }
    @media (min-width: 1200px) {
      .Header_wrapper__IwGFH .Header_crowd__2Afrl {
        left: 47%;
        bottom: -45%;
        width: 100%; } }
    @media (min-width: 1600px) {
      .Header_wrapper__IwGFH .Header_crowd__2Afrl {
        left: 46%;
        bottom: -50%;
        width: 85%; } }
  .Header_wrapper__IwGFH h1 {
    color: #FAFAFA;
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: 1px;
    padding: 3rem 1.5rem; }

.About_wrapper__V5ECC {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 0;
  background-color: #141414;
  color: #fafafa; }
  @media (min-width: 720px) {
    .About_wrapper__V5ECC {
      padding: 1rem 3rem; } }
  @media (min-width: 1200px) {
    .About_wrapper__V5ECC {
      display: grid;
      grid-template-columns: 55% 1fr;
      align-items: center;
      padding: 0; } }

.About_right__1N9sh {
  padding: 1rem; }
  @media (min-width: 720px) {
    .About_right__1N9sh {
      padding: 2rem 3rem; } }
  @media (min-width: 1200px) {
    .About_right__1N9sh {
      -webkit-clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
              clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
      background-color: #332E30;
      height: 100%; } }
  @media (min-width: 1600px) {
    .About_right__1N9sh {
      padding: 3rem 5rem; } }
  .About_right__1N9sh .About_title__319T6 {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase; }
    @media (min-width: 1200px) {
      .About_right__1N9sh .About_title__319T6 {
        font-size: 3rem;
        margin-top: 5rem; } }
    @media (min-width: 1600px) {
      .About_right__1N9sh .About_title__319T6 {
        font-size: 3.5rem; } }
    .About_right__1N9sh .About_title__319T6::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934;
      z-index: 5; }
  .About_right__1N9sh .About_p__2jOaa {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%; }
    @media (min-width: 1200px) {
      .About_right__1N9sh .About_p__2jOaa {
        font-size: 1.3rem;
        margin: 1rem;
        letter-spacing: 1px; } }
    @media (min-width: 1600px) {
      .About_right__1N9sh .About_p__2jOaa {
        font-size: 1.5rem; } }

.About_left__1br-Z {
  padding: 1rem;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 45%;
  grid-auto-rows: 1fr 1fr;
  grid-gap: .5rem;
  grid-template-areas: "firstImg secondtImg" "thirdtImg secondtImg"; }
  @media (min-width: 720px) {
    .About_left__1br-Z {
      padding: 2rem 1rem; } }
  @media (min-width: 1200px) {
    .About_left__1br-Z {
      text-align: center;
      justify-content: center;
      grid-gap: 0; } }
  .About_left__1br-Z .About_bandImg__2Rf40 {
    width: 90%;
    display: inline-block;
    -webkit-clip-path: polygon(50% 0%, 100% 4%, 100% 60%, 98% 100%, 0 100%, 0 10%, 17% 0);
            clip-path: polygon(50% 0%, 100% 4%, 100% 60%, 98% 100%, 0 100%, 0 10%, 17% 0); }
    .About_left__1br-Z .About_bandImg__2Rf40:nth-child(1) {
      grid-area: firstImg; }
    .About_left__1br-Z .About_bandImg__2Rf40:nth-child(2) {
      grid-area: secondtImg;
      -webkit-clip-path: polygon(12% 2%, 96% 0, 100% 46%, 91% 100%, 4% 100%, 0 47%, 0 0);
              clip-path: polygon(12% 2%, 96% 0, 100% 46%, 91% 100%, 4% 100%, 0 47%, 0 0); }
      @media (min-width: 1200px) {
        .About_left__1br-Z .About_bandImg__2Rf40:nth-child(2) {
          margin: 0 auto 0 0; } }
    .About_left__1br-Z .About_bandImg__2Rf40:nth-child(3) {
      grid-area: thirdtImg;
      -webkit-clip-path: polygon(68% 4%, 100% 0, 100% 46%, 95% 100%, 14% 100%, 0 85%, 3% 0);
              clip-path: polygon(68% 4%, 100% 0, 100% 46%, 95% 100%, 14% 100%, 0 85%, 3% 0); }
    @media (min-width: 720px) {
      .About_left__1br-Z .About_bandImg__2Rf40 {
        width: 90%;
        margin: 0 auto 1rem; } }
    @media (min-width: 1200px) {
      .About_left__1br-Z .About_bandImg__2Rf40 {
        width: 80%; } }
  .About_left__1br-Z .About_person__2sxSN {
    text-align: center;
    font-size: 1rem;
    font-weight: 300; }
    @media (min-width: 1200px) {
      .About_left__1br-Z .About_person__2sxSN {
        font-size: 1.2rem;
        display: inline;
        margin: 0 1rem; } }
    @media (min-width: 1600px) {
      .About_left__1br-Z .About_person__2sxSN {
        font-size: 1.4rem;
        margin: 0 2rem; } }

.Trasa_wrapper__2Ekay {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 0;
  background-color: #332E30;
  color: #fafafa; }
  @media (min-width: 720px) {
    .Trasa_wrapper__2Ekay {
      padding: 3rem; } }
  @media (min-width: 1200px) {
    .Trasa_wrapper__2Ekay {
      display: grid;
      grid-template-columns: 45% 1fr;
      align-items: center;
      padding: 0; } }

.Trasa_left__3Fe4v {
  padding: 1rem; }
  @media (min-width: 720px) {
    .Trasa_left__3Fe4v {
      padding: 2rem 3rem; } }
  @media (min-width: 1200px) {
    .Trasa_left__3Fe4v {
      height: 100%; } }
  @media (min-width: 1600px) {
    .Trasa_left__3Fe4v {
      padding: 3rem 5rem; } }
  .Trasa_left__3Fe4v .Trasa_title__uA-M_ {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase; }
    @media (min-width: 1200px) {
      .Trasa_left__3Fe4v .Trasa_title__uA-M_ {
        font-size: 3rem;
        margin-top: 5rem; } }
    @media (min-width: 1600px) {
      .Trasa_left__3Fe4v .Trasa_title__uA-M_ {
        font-size: 3.5rem; } }
    .Trasa_left__3Fe4v .Trasa_title__uA-M_::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934; }
  .Trasa_left__3Fe4v .Trasa_p__uxfZs {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%; }
    @media (min-width: 1200px) {
      .Trasa_left__3Fe4v .Trasa_p__uxfZs {
        font-size: 1.3rem;
        margin: 1rem;
        letter-spacing: 1px; } }
    @media (min-width: 1600px) {
      .Trasa_left__3Fe4v .Trasa_p__uxfZs {
        font-size: 1.5rem; } }

.Trasa_right__kTjQF {
  padding: 1rem; }
  @media (min-width: 1200px) {
    .Trasa_right__kTjQF {
      -webkit-clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
              clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
      background-color: #141414;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: url(/static/media/header-bg2.ec75e4e9.jpg);
      background-position: center;
      background-size: cover; } }
  .Trasa_right__kTjQF .Trasa_chinaMap__3LkZK {
    width: 100%; }
    @media (min-width: 720px) {
      .Trasa_right__kTjQF .Trasa_chinaMap__3LkZK {
        width: 50%;
        display: block;
        margin: 0 auto; } }

.Discography_wrapper__3I9Ck {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 0 0 0;
  background-color: #141414;
  color: #fafafa;
  grid-template-areas: "leftSite" "rightSite" "bottomSite"; }
  @media (min-width: 720px) {
    .Discography_wrapper__3I9Ck {
      padding: 1rem 3rem; } }
  @media (min-width: 1200px) {
    .Discography_wrapper__3I9Ck {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 15vh;
      align-items: center;
      padding: 0;
      grid-template-areas: "leftSite rightSite" "bottomSite bottomSite"; } }

.Discography_left__MDp9U {
  padding: 1rem;
  grid-area: leftSite; }
  @media (min-width: 720px) {
    .Discography_left__MDp9U {
      padding: 2rem 3rem; } }
  @media (min-width: 1600px) {
    .Discography_left__MDp9U {
      padding: 3rem 5rem;
      margin-left: .5rem; } }
  .Discography_left__MDp9U .Discography_title__wFiE7 {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase; }
    @media (min-width: 1200px) {
      .Discography_left__MDp9U .Discography_title__wFiE7 {
        font-size: 3rem;
        margin-top: 5rem; } }
    @media (min-width: 1600px) {
      .Discography_left__MDp9U .Discography_title__wFiE7 {
        font-size: 3.5rem; } }
    .Discography_left__MDp9U .Discography_title__wFiE7::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934; }
  .Discography_left__MDp9U .Discography_p__3lCy0 {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%; }
    @media (min-width: 1200px) {
      .Discography_left__MDp9U .Discography_p__3lCy0 {
        font-size: 1.3rem;
        margin: 1rem;
        letter-spacing: 1px; } }
    @media (min-width: 1600px) {
      .Discography_left__MDp9U .Discography_p__3lCy0 {
        font-size: 1.5rem; } }
  .Discography_left__MDp9U .Discography_albums__1fGiH {
    padding: 0 1rem;
    font-size: 1.4rem;
    color: #FF2934;
    text-transform: uppercase;
    font-weight: 700; }
    @media (min-width: 1600px) {
      .Discography_left__MDp9U .Discography_albums__1fGiH {
        font-size: 1.5rem; } }

.Discography_right__oSOb1 {
  padding: 1rem;
  height: 100%;
  grid-area: rightSite; }
  @media (min-width: 1200px) {
    .Discography_right__oSOb1 {
      padding: 3rem; } }
  .Discography_right__oSOb1 .Discography_video__3oltR {
    margin: 0 auto; }
    @media (min-width: 720px) {
      .Discography_right__oSOb1 .Discography_video__3oltR {
        padding: 3rem 0; } }
    @media (min-width: 1200px) {
      .Discography_right__oSOb1 .Discography_video__3oltR {
        padding: 3rem 2rem;
        width: 100% !important; } }
    @media (min-width: 1600px) {
      .Discography_right__oSOb1 .Discography_video__3oltR {
        font-size: 4rem;
        -webkit-transform: translateX(-20%);
                transform: translateX(-20%); } }

.Discography_partners__J7_ql {
  width: 100%;
  background-color: #332E30;
  grid-area: bottomSite; }
  @media (min-width: 1200px) {
    .Discography_partners__J7_ql {
      height: 100%;
      -webkit-clip-path: polygon(71% 5%, 100% 0, 90% 17%, 100% 42%, 100% 100%, 0 100%, 0 0, 30% 9%, 44% 0, 59% 9%);
              clip-path: polygon(71% 5%, 100% 0, 90% 17%, 100% 42%, 100% 100%, 0 100%, 0 0, 30% 9%, 44% 0, 59% 9%);
      display: flex; } }
  @media (min-width: 1600px) {
    .Discography_partners__J7_ql {
      text-align: center; } }
  .Discography_partners__J7_ql .Discography_partnersTitle__2ubAf {
    padding: 1.5rem;
    font-size: 1.3rem; }
    @media (min-width: 1200px) {
      .Discography_partners__J7_ql .Discography_partnersTitle__2ubAf {
        padding: 2rem;
        font-size: 1.6rem; } }
    @media (min-width: 1600px) {
      .Discography_partners__J7_ql .Discography_partnersTitle__2ubAf {
        font-size: 2rem; } }
  .Discography_partners__J7_ql .Discography_imgDiv__2oq5O {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1200px) {
      .Discography_partners__J7_ql .Discography_imgDiv__2oq5O {
        flex-direction: row; } }
  .Discography_partners__J7_ql .Discography_partnersLink__12VsL {
    padding: .5rem; }
    @media (min-width: 1200px) {
      .Discography_partners__J7_ql .Discography_partnersLink__12VsL {
        padding: 1rem; } }
    .Discography_partners__J7_ql .Discography_partnersLink__12VsL .Discography_partnersImg__iO0Oc {
      display: inline-block;
      width: 5rem; }
      .Discography_partners__J7_ql .Discography_partnersLink__12VsL .Discography_partnersImgSoft__1oJm1 {
        display: inline-block;
        width: 4.3rem; }
        @media (min-width: 1200px) {
          .Discography_partners__J7_ql .Discography_partnersLink__12VsL .Discography_partnersImgSoft__1oJm1 {
            width: 5.3rem; } }
      @media (min-width: 1200px) {
        .Discography_partners__J7_ql .Discography_partnersLink__12VsL .Discography_partnersImg__iO0Oc {
          width: 6rem; } }

.Navigation_wrapper__2fbpu {
  position: fixed;
  top: 50%;
  right: 2%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10; }
  .Navigation_wrapper__2fbpu ul {
    list-style: none;
    color: #c9c9c9; }
    .Navigation_wrapper__2fbpu ul .Navigation_navLink__2THVz {
      border: 2px solid #c9c9c9;
      width: .5rem;
      height: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      padding: .2rem;
      border-radius: 50%;
      cursor: pointer;
      font-size: .8rem;
      box-shadow: 0px 0px 5px 1px rgba(250, 250, 250, 0.5); }
      @media (min-width: 1200px) {
        .Navigation_wrapper__2fbpu ul .Navigation_navLink__2THVz {
          width: 1rem;
          height: 1rem; } }
      .Navigation_wrapper__2fbpu ul .Navigation_navLinkActive__302EM {
        color: #3d3d3d;
        border: 2px solid #3d3d3d;
        background-color: #FF2934; }

