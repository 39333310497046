.wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 0;
  background-color: #332E30;
  color: #fafafa;

  @media (min-width:720px) {
    padding: 3rem;
  }
  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 45% 1fr;
    align-items: center;
    padding: 0;
  }
}

.left {
  padding: 1rem;

  @media (min-width:720px) {
    padding: 2rem 3rem;
  }
  @media (min-width:1200px) {
    height: 100%;
  }
  @media (min-width:1600px) {
    padding: 3rem 5rem;
  }

  .title {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase;

    @media (min-width:1200px) {
      font-size: 3rem;
      margin-top: 5rem;
    }
    @media (min-width:1600px) {
      font-size: 3.5rem;
    }
  
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934;
    }
  }
  .p {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%;

    @media (min-width:1200px) {
      font-size: 1.3rem;
      margin: 1rem;
      letter-spacing: 1px;
    }
    @media (min-width:1600px) {
      font-size: 1.5rem;
    }
  }
}

.right {
  padding: 1rem;

  @media (min-width:1200px) {
    clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
    background-color: #141414;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(../assets/images/background/header-bg2.jpg);
    background-position: center;
    background-size: cover;
  }

  .chinaMap {
    width: 100%;

    @media (min-width:720px) {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }
}