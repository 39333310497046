.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-image: url(../assets/images/background/header-bg7.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;

  .logo {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);

    @media (min-width:720px) {
      top: 25%;
      width: 50%;
    }
    @media (min-width:1200px) {
      top: 20%;
      width: 30%;
    }
    @media (min-width:1600px) {
      top: 10%;
      width: 30%;
    }
  }

  .crowd {
    position: absolute;
    left: 50%;
    bottom: -30%;
    width: 200%;
    transform: translate(-50%, -50%);

    @media (min-width:720px) {
      width: 150%;
    }
    @media (min-width:1200px) {
      left: 47%;
      bottom: -45%;
      width: 100%;
    }
    @media (min-width:1600px) {
      left: 46%;
      bottom: -50%;
      width: 85%;
    }
  }

  h1 {
    color: #FAFAFA;
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: 1px;
    padding: 3rem 1.5rem;
  }
}