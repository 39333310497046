.wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 0;
  background-color: #141414;
  color: #fafafa;
  

  @media (min-width:720px) {
    padding: 1rem 3rem;
  }
  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 55% 1fr;
    align-items: center;
    padding: 0;

  }
}

.right {
  padding: 1rem;

  @media (min-width:720px) {
    padding: 2rem 3rem;
  }
  @media (min-width:1200px) {
    clip-path: polygon(5% 10%, 0 0, 100% 0, 100% 100%, 0 100%, 0 85%, 5% 73%, 0 63%, 7% 46%, 0 26%);
    background-color: #332E30;
    height: 100%;
  }
  @media (min-width:1600px) {
    padding: 3rem 5rem;
  }

  .title {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: #DDDDDD;
    text-transform: uppercase;
  
    @media (min-width:1200px) {
      font-size: 3rem;
      margin-top: 5rem;
    }
    @media (min-width:1600px) {
      font-size: 3.5rem;
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: -10%;
      width: 60%;
      height: 3px;
      background-color: #FF2934;
      z-index: 5;
    }
  }
  .p {
    font-size: 1.1rem;
    padding: .3rem 1rem;
    line-height: 150%;

    @media (min-width:1200px) {
      font-size: 1.3rem;
      margin: 1rem;
      letter-spacing: 1px;
    }
    @media (min-width:1600px) {
      font-size: 1.5rem;
    }
  }
}

.left {
  padding: 1rem;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 45%;
  grid-auto-rows: 1fr 1fr;
  grid-gap: .5rem;
  grid-template-areas:
  "firstImg secondtImg"
  "thirdtImg secondtImg";

  @media (min-width:720px) {
    padding: 2rem 1rem;
  }
  @media (min-width:1200px) {
    text-align: center;
    justify-content: center;
    grid-gap: 0;
  }

  .bandImg {
    width: 90%;
    display: inline-block;
    clip-path: polygon(50% 0%, 100% 4%, 100% 60%, 98% 100%, 0 100%, 0 10%, 17% 0);

    &:nth-child(1) {
      grid-area: firstImg;
    }
    &:nth-child(2) {
      grid-area: secondtImg;
      clip-path: polygon(12% 2%, 96% 0, 100% 46%, 91% 100%, 4% 100%, 0 47%, 0 0);

      @media (min-width:1200px) {
        margin: 0 auto 0 0;
      }
    }
    &:nth-child(3) {
      grid-area: thirdtImg;
      clip-path: polygon(68% 4%, 100% 0, 100% 46%, 95% 100%, 14% 100%, 0 85%, 3% 0);
    }

    @media (min-width:720px) {
      width: 90%;
      margin: 0 auto 1rem;
    }
    @media (min-width:1200px) {
      width: 80%;
    }
  }

  .person {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;

    @media (min-width:1200px) {
      font-size: 1.2rem;
      display: inline;
      margin: 0 1rem;
    }
    @media (min-width:1600px) {
      font-size: 1.4rem;
      margin: 0 2rem;
    }
  }
}