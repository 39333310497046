.wrapper {
  position:  fixed;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  z-index: 10;

  ul {
    list-style: none;
    color: #c9c9c9;

    .navLink {
      border: 2px solid #c9c9c9;
      width: .5rem;
      height: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      padding: .2rem;
      border-radius: 50%;
      cursor: pointer;
      font-size: .8rem;
      box-shadow: 0px 0px 5px 1px rgba(250,250,250,0.5); 

      @media (min-width:1200px) {
        width: 1rem;
        height: 1rem;;
      }
      
      &Active {
        color: #3d3d3d;
        border: 2px solid #3d3d3d;
        background-color: #FF2934;
      }
    }
  }
}
